import React from 'react'
import Layout from '../components/layout'
import CurriculumGroups from '../components/CurriculumGroups'

const CurriculumGroupsPage = () => (
  <Layout>
    <CurriculumGroups />
  </Layout>
)

export default CurriculumGroupsPage
