import styled from 'styled-components'
import { colors } from '../../theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  > :first-child {
    max-width: 500px;
    display: flex;
    margin: 0 auto;
    > :first-child {
      margin-right: 10px;
    }
  }
`

export const Groups = styled.div`
  margin-top: 30px;
  display: flex;
  a {
    color: inherit;
    text-decoration: none;
  }
`

export const Group = styled.div`
  width: 130px;
  height: 130px;
  background-color: ${colors.green};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  border-radius: 8px;

`
