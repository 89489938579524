import React, { useState } from "react"
import { Wrapper, Groups, Group } from "./index.styles"
import { useContainer } from "unstated-next"
import { CurriculumGroupsState } from "./useCurriculumGroups"
import { TextInput, Button } from "grommet"
import { Link } from "gatsby"

const CurriculumGroups = props => {
  const { curriculumGroups, add } = useContainer(CurriculumGroupsState)
  const [newCurriculumGroup, setNewCurriculumGroup] = useState({ title: "" })

  const onAdd = () => {
    add({
      title: newCurriculumGroup.title,
    })
    setNewCurriculumGroup({ title: "" })
  }

  return (
    <Wrapper>
      <div>
        <TextInput
          value={newCurriculumGroup.title}
          onChange={e =>
            setNewCurriculumGroup({
              ...newCurriculumGroup,
              title: e.target.value,
            })
          }
        />
        <Button primary onClick={onAdd}>
          Add
        </Button>
      </div>
      <Groups>
        {curriculumGroups.map(curriculumGroup => (
          <Link to={`/curriculum-group/${curriculumGroup._id}`}>
            <Group key={curriculumGroup._id}>{curriculumGroup.title}</Group>
          </Link>
        ))}
      </Groups>
    </Wrapper>
  )
}

CurriculumGroups.propTypes = {}

CurriculumGroups.defaultProps = {}

export default props => (
  <CurriculumGroupsState.Provider>
    <CurriculumGroups {...props} />
  </CurriculumGroupsState.Provider>
)
