import { useState, useEffect } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import GET_CURRICULUM_GROUPS from './getCurriculumGroups.graphql'
import CREATE_CURRICULUM_GROUPS from './createCurriculumGroup.graphql'
import { createContainer } from 'unstated-next'

export const useCurriculumGroups = () => {
  const [curriculumGroups, setCurriculumGroups] = useState([])
  const client = useApolloClient()

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    const { data } = await client.query({
      query: GET_CURRICULUM_GROUPS,
      fetchPolicy: 'network-only'
    })
    setCurriculumGroups(data && data.curriculumGroups)
  }

  const add = async ({ title }) => {
    const { data } = await client.mutate({
      mutation: CREATE_CURRICULUM_GROUPS,
      variables: { title }
    })
    setCurriculumGroups([data.createCurriculumGroup, ...curriculumGroups])
  }

  return {
    fetch,
    curriculumGroups,
    add
  }
}

export const CurriculumGroupsState = createContainer(useCurriculumGroups)
